<template>
  <div>
    <p>{{dato}}</p>
  </div>
</template>
<script>
export default {
  name: "vercategoria",
  props: ["idserv"],
  data() {
    return {
      dato: "",
      categorias: [],
      mensaje: "",
      size: 0
    };
  },
  created() {
    this.categorias = this.idserv.data;
    for(let i = 0; i< this.categorias.length; i++){
        if(this.categorias[i].idcategoriaservicio == this.idserv.ids){
            this.dato = this.categorias[i].descripcion
        }
    }
  }
};
</script>