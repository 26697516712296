<template>
  <v-container fluid fill-height>
    <v-layout align-top justify-center>
      <v-flex xs12>
        <v-card elevation="0">
          <v-card-title>
            CONTROL DE SERVICIOS
            <v-spacer></v-spacer>
            <v-select
              dense
              hide-selected
              outlined
              label="SERVICIO"
              v-model="servicio"
              :items="servicios"
              item-text="nombre"
              item-value="idservicio"
              :rules="valselectservicio"
              @change="cargarelemts(servicio)"
            ></v-select>
          </v-card-title>

          <v-data-iterator hide-default-footer :items="apis" :search="search">
            <template v-slot:header>
              <v-toolbar dark color="secondary" class="mb-1">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="search"
                      clearable
                      flat
                      solo-inverted
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Buscador"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:no-data>
              <v-alert border="right" colored-border type="error" elevation="2">
                No hay información disponble en este servicio.
              </v-alert>
            </template>

            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-card elevation="5">
                    <v-card-title class="subtitle-2">
                      <v-row>
                        <v-col cols="12" sm="10" md="10">
                          <span>{{ item.razonsocial }}</span>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(est, i) in estados"
                                :key="i"
                                @click="changesave(item, i)"
                              >
                                <v-alert
                                  dense
                                  outlined
                                  :type="est.color"
                                  v-if="est.action == true"
                                  >{{ est.estado }}</v-alert
                                >
                                <v-alert
                                  dense
                                  :type="est.color"
                                  v-if="est.action == false"
                                  >{{ est.estado }}</v-alert
                                >
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle>
                      <v-alert
                        dense
                        outlined
                        text
                        class="body-2"
                        :type="estados[item.estado].color"
                        >{{ item.ruc }}</v-alert
                      >
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-btn icon @click="cargartocken(item)">
                        <v-icon large color="deep-blue lighten-2"
                          >mdi-folder-key-network</v-icon
                        >
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="orange ligzhten-2"
                        icon
                        @click="editItemsub(item)"
                      >
                        <v-icon large color="blue darken-2"
                          >mdi-plus-circle-outline</v-icon
                        >
                      </v-btn>
                      <v-btn
                        color="orange lighten-2"
                        icon
                        @click="editItem(item)"
                      >
                        <v-icon large color="orange darken-2"
                          >mdi-notebook-edit-outline</v-icon
                        >
                      </v-btn>
                      <v-btn
                        color="orange lighten-2"
                        icon
                        @click="deleteItem(item)"
                      >
                        <v-icon large color="red darken-2"
                          >mdi-delete-outline</v-icon
                        >
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialogd" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">EJECUTABLE DEL SERVICIO</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="9">
                <v-file-input accept=".exe" label="Ejecutable"></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="guardarejecutable()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn bottom color="secondary" dark fab fixed right @click="editItem()">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogtitle }}</span>
          <v-spacer></v-spacer>
          <v-icon large @click="dialog = !dialog"> mdi-close-circle </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Empresa*"
                  required
                  v-model="selectempresa"
                  :items="empresas"
                  item-text="razonsocial"
                  item-value="ruc"
                  return-object
                  single-line
                  :disabled="desabilitar"
                  :rules="valselectempresa"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Servicio*"
                  required
                  v-model="selectservicio"
                  :items="servicios"
                  item-text="nombre"
                  item-value="idservicio"
                  return-object
                  single-line
                  :disabled="desabilitar"
                  :rules="valselectservicio"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="API PUBLICA*"
                  hint="http://localhost:0000/"
                  v-model="api"
                  required
                  :rules="valapi"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="API LOCAL*"
                  hint="http://localhost:0000/"
                  v-model="api2"
                  required
                  :rules="valapi"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Servidor*"
                  v-model="servidor"
                  hint="Ejmp: SERVIDOR, SERVIDOR\INSTANCIA, etc"
                  required
                  :rules="valservidor"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Puerto*"
                  v-model="puerto"
                  hint="Ejmp: 1433"
                  required
                  :rules="valpuerto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Usuario*"
                  v-model="usuario"
                  required
                  :rules="valusuario"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Contraseña*"
                  v-model="contrasenia"
                  required
                  :rules="valcontrasenia"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Base de Datos*"
                  v-model="basedatos"
                  required
                  :rules="valbasedatos"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="N° de Licencias*"
                  v-model="nrolicencias"
                  hint="Ejmp: 10 Lic. "
                  required
                  :rules="valnrolicencias"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            v-if="desabilitar == false"
            text
            @click="guardardata()"
            >Guardar</v-btn
          >
          <v-btn
            color="blue darken-1"
            v-if="desabilitar == true"
            text
            @click="guardardata()"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogsub" max-width="1000px">
      <v-card>
        <v-card-title>
          {{ empresa.idempresa }}
          <span> - </span>
          {{ licencia.razonsocial }}
          <v-spacer></v-spacer>
          <v-icon large @click="dialogsub = !dialogsub">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-subtitle>
          <span>{{ licencia.ruc }}</span>
        </v-card-subtitle>
        <v-card-text>
          <template>
            <v-row align="center" justify="center">
              <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="viewlistdialog = !viewlistdialog"
                >Nueva Sub-licencias</v-btn
              >

              <v-btn
                class="ma-2"
                outlined
                color="blue"
                @click="viewlistdialog = !viewlistdialog"
                >Lista de Sub-licencias</v-btn
              >
            </v-row>
          </template>
          <v-container v-if="viewlistdialog == false">
            <v-row align="center">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                color="primary"
                dark
                @click="addsublicencia(licencia)"
              >
                Añadir
              </v-btn>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="SubEmpresa*"
                  required
                  v-model="selectsubempresa"
                  :items="subempresas"
                  item-text="descripcion"
                  item-value="idsubempresa"
                  return-object
                  single-line
                  :disabled="false"
                  :rules="valselectsubempresa"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Servicio*"
                  required
                  v-model="servicio"
                  :items="servicios"
                  item-text="nombre"
                  item-value="idservicio"
                  return-object
                  single-line
                  :disabled="true"
                  :rules="valselectservicio"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="API PUBLICA*"
                  hint="http://localhost:0000/"
                  v-model="apisub"
                  required
                  :rules="valapi"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="API LOCAL*"
                  hint="http://localhost:0000/"
                  v-model="apisub2"
                  required
                  :rules="valapi"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Servidor*"
                  v-model="servidorsub"
                  hint="Ejmp: SERVIDOR, SERVIDOR\INSTANCIA, etc"
                  required
                  :rules="valservidor"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Usuario*"
                  v-model="usuariosub"
                  required
                  :rules="valusuario"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Contraseña*"
                  v-model="contraseniasub"
                  required
                  :rules="valcontrasenia"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Base de Datos*"
                  v-model="basedatossub"
                  required
                  :rules="valbasedatos"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="N° LICENCIAS*"
                  v-model="nrolicenciassub"
                  required
                  :rules="valnrolicencias"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="viewlistdialog == true">
            <v-data-table
              :headers="sublicenciasheadres"
              :items="sublicencias"
              loading
              loading-text="SIN INFORMACIÓN"
              v-if="sublicencias.length === 0"
            ></v-data-table>
            <v-data-table
              :headers="sublicenciasheadres"
              :items="sublicencias"
              sort-by="descripcion"
              class="elevation-1"
              :items-per-page="5"
              v-if="sublicencias.length > 0"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteItemsub(item, licencia)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:[`item.api`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.api"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.api }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.api"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.apiv`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.apiv"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.apiv }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.api2"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.servidor`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.servidor"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.servidor }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.servidor"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.contrasenia`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.contrasenia"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.contrasenia }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.contrasenia"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.basedatos`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.basedatos"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.basedatos }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.basedatos"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.nrolicencias`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.nrolicencias"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.nrolicencias }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.nrolicencias"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.usuario`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.usuario"
                  large
                  persistent
                  @save="savesub(props.item, licencia)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <div>{{ props.item.usuario }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.usuario"
                      label="SUB EMPRESA"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogt" max-width="400px">
      <v-card>
        <v-card-title>
          {{ tocken.razonsocial }}
          <v-spacer></v-spacer>
          <v-icon large @click="dialogt = !dialogt"> mdi-close-circle </v-icon>
        </v-card-title>
        <v-card-subtitle>
          {{ tocken.tiposervicio }}
          <span> - </span>
          {{ tocken.nombreservicio }}
        </v-card-subtitle>
        <v-card-text>
          <v-row align="center">
            <v-col class="headline" cols="3"> API: </v-col>
            <v-col cols="9">
              <span> {{ tocken.apiservicio }} </span>
            </v-col>
          </v-row>
          <input type="hidden" id="testing-code" :value="tocken.tocken" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            rounded
            text
            color="teal"
            @click.stop.prevent="copyTestingCode"
          >
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import vercategoria from "../components/vercategoria";

export default {
  name: "controlservicio",
  components: {
    vercategoria,
  },
  data() {
    return {
      dialogtitle: "",
      search: "",
      selectservicio: "",
      servicio: "",
      selectempresa: "",
      selectsubempresa: "",
      tocken: {},
      sublicencias: [],
      licencia: {},
      desserts: [],
      empresas: [],
      empresa: {},
      subempresas: [],
      servicios: [],
      apis: [],
      sublicenciasheadres: [
        {
          text: "SUBEMPRESA",
          value: "subempresa",
        },
        {
          text: "API PUBLICA",
          value: "api",
        },
        {
          text: "API LOCAL",
          value: "apiv",
        },
        {
          text: "SERVIDOR",
          value: "servidor",
        },
        {
          text: "USUARIO",
          value: "usuario",
        },
        {
          text: "CONTRASEÑA",
          value: "contrasenia",
        },
        {
          text: "BASE",
          value: "basedatos",
        },
        {
          text: "N° LICENCIAS",
          value: "nrolicencias",
        },
        {
          text: "ACCIONES",
          value: "actions",
          sortable: false,
        },
      ],
      estados: [
        {
          estado: "Desactivar",
          color: "error",
          action: true,
        },
        {
          estado: "Activar",
          color: "success",
          action: true,
        },
        {
          estado: "SINF",
          color: "info",
          action: false,
        },
        {
          estado: "Alerta",
          color: "warning",
          action: false,
        },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
      categorias: [],
      mensaje: "",
      size: 0,
      dialogd: false,
      dialog: false,
      desabilitar: false,
      dialoge: false,
      dialogt: false,
      dialogsub: false,
      viewlistdialog: false,
      data: null,
      estado: "",
      idlicencia: "",
      api: "",
      api2: "",
      servidor: "",
      usuario: "",
      contrasenia: "",
      basedatos: "",
      apisub: "",
      apisub2: "",
      servidorsub: "",
      usuariosub: "",
      contraseniasub: "",
      basedatossub: "",

      nrolicenciassub: 0,

      puerto: 1433,
      nrolicencias: 0,

      valapi: [(value) => !!value || "Ingresa la dirección del API"],
      valservidor: [(value) => !!value || "Ingresa el nombre del servidor SQL"],
      valusuario: [(value) => !!value || "Ingresar usuario del servidor SQL"],
      valcontrasenia: [
        (value) => !!value || "Ingresa Contraseña del servidor SQL",
      ],
      valpuerto: [
        (value) => !!value || "Ingresar un puerto de comunicacion SQL",
        (value) =>
          Number(value) > 0 || "valor tiene que ser numérico mayor a 0",
      ],
      valnrolicencias: [
        (value) => !!value || "Nro de licencias es requerido",
        (value) =>
          Number(value) >= 0 || "el valor deve ser mayor o igual a cero",
      ],

      valbasedatos: [(value) => !!value || "Ingresa el nombre de la BD"],
      valselectservicio: [(value) => !!value || "Seleccione un Servicio"],
      valselectempresa: [(value) => !!value || "Seleccione una empresa"],
      valselectsubempresa: [(value) => !!value || "Seleccione una subempresa"],
    };
  },
  created() {
    this.cargardatacontrol(),
      this.cargardataempresa(),
      this.cargardataservicio();
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.cargardataservicio();
      }
    },
  },
  methods: {
    formatdate() {
      this.selectsubempresa = "";
      this.apisub = "";
      this.apisub2 = "";
      this.servidorsub = "";
      this.usuariosub = "";
      this.contraseniasub = "";
      this.basedatossub = "";
    },
    save(item) {
      var url = "lic/update/";
      var result = null;
      const postData = {
        idlicencia: item.idlicencia,
        ruc: item.ruc,
        idservicio: item.idservicio,
        api: item.api,
        api2: item.apiv,
        servidor: item.servidor,
        usuario: item.usuario,
        contrasenia: item.contrasenia,
        basedatos: item.basedatos,
        estado: item.estado,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.size = result.size;
          this.mensaje = result.objeto[0].mensaje;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = this.mensaje;
        }
      });
      this.cargardatacontrol();
    },
    deleteItem(item) {
      var url = "lic/delete/";
      var result = null;
      const postData = {
        idlicencia: item.idlicencia,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.size = result.size;
          this.mensaje = result.objeto[0].mensaje;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = this.mensaje;
        }
      });
      this.cargardatacontrol();
    },
    changesave(item, estado) {
      console.log("estado", estado);
      if (this.estados[estado].action == true) {
        var url = "lic/update/";
        var result = null;
        const postData = {
          idlicencia: item.idlicencia,
          ruc: item.ruc,
          idservicio: item.idservicio,
          api: item.api,
          api2: item.apiv,
          servidor: item.servidor,
          usuario: item.usuario,
          contrasenia: item.contrasenia,
          basedatos: item.basedatos,
          estado: estado,
        };
        this.$http.post(url, postData).then((res) => {
          if (res.status === 200 || res.status === 304) {
            result = res.body;
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = this.mensaje;
          }
        });
        this.cargardatacontrol();
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Cancelado";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Abriendo edición";
    },
    close() {
      console.log("Dialogo cerrado");
    },
    dialogcat(value, data) {
      this.dialogd = value;
      if (value == true) {
        this.data = data;
      }
    },
    dialogedit(value, data) {
      this.dialog = value;
      if (value == true) {
        this.data = data;
      }
    },
    editItem(item) {
      if (item == undefined) {
        this.desabilitar = false;
        this.dialogtitle = "NUEVO SERVICIO";
        this.api = "";
        this.api2 = "";
        this.selectempresa = "";
        this.selectservicio = "";
        this.servidor = "";
        this.usuario = "";
        this.contrasenia = "";
        this.basedatos = "";
        this.dialog = true;
        this.nrolicencias = 0;
      } else {
        this.desabilitar = true;
        this.dialogtitle = "EDITAR SERVICIO";
        this.idlicencia = item.idlicencia;
        this.api = item.api;
        (this.api2 = item.apiv), (this.selectempresa = item.ruc);
        this.selectservicio = item.idservicio;
        this.servidor = item.servidor;
        this.usuario = item.usuario;
        this.contrasenia = item.contrasenia;
        this.basedatos = item.basedatos;
        this.dialog = true;
        this.estado = item.estado;
        this.puerto = item.puerto ?? 1433;
        this.nrolicencias = item.nrolicencias ?? 0;
      }
    },
    cargartocken(item) {
      var url = "ver/2/" + item.ruc + "&s=" + item.idservicio;
      var result = null;
      this.$http.get(url).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          if (result.size > 0) {
            this.tocken = result.objeto[0];
            this.dialogt = true;
            console.log("Resultado", result.objeto);
          } else {
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
            console.log(this.mensaje);
          }
        }
      });
    },
    guardardata() {
      console.log(typeof this.puerto);
      console.log(typeof this.nrolicencias);
      if (
        this.api.trim() == "" ||
        this.api2.trim() == "" ||
        this.selectempresa == "" ||
        this.selectservicio == "" ||
        this.servidor.trim() == "" ||
        this.usuario.trim() == "" ||
        this.contrasenia.trim() == "" ||
        this.basedatos.trim() == ""
      ) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Complete todos los campos";
      } else {
        var url;
        var idser;
        var rucdes;
        if (this.desabilitar) {
          url = "lic/update/";
          rucdes = this.selectempresa;
          idser = this.selectservicio;
        } else {
          url = "lic/";
          rucdes = this.selectempresa.ruc;
          idser = this.selectservicio.idservicio;
        }
        var result = null;

        console.log(
          typeof this.nrolicencias === "number"
            ? Number.parseInt(this.nrolicencias ?? 0)
            : typeof this.nrolicencias === "string"
            ? Number.parseInt(this.nrolicencias.trim() ?? "0")
            : 0
        );

        const postData = {
          idlicencia: this.idlicencia,
          estado: this.estado,
          ruc: rucdes,
          idservicio: idser,
          api: this.api.trim(),
          api2: this.api2.trim(),
          servidor: this.servidor.trim(),
          usuario: this.usuario.trim(),
          contrasenia: this.contrasenia.trim(),
          basedatos: this.basedatos.trim(),
          puerto:
            typeof this.puerto === "number"
              ? Number.parseInt(this.puerto ?? 1433)
              : typeof this.puerto === "string"
              ? Number.parseInt(this.puerto.trim() ?? "1433")
              : 1433,
          nrolicencias:
            typeof this.nrolicencias === "number"
              ? Number.parseInt(this.nrolicencias ?? 0)
              : typeof this.nrolicencias === "string"
              ? Number.parseInt(this.nrolicencias.trim() ?? "0")
              : 0,
        };

        this.$http.post(url, postData).then((res) => {
          if (res.status === 200 || res.status === 304) {
            result = res.body;

            console.log(result);

            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = this.mensaje;
          }
        });
      }
    },
    cargardatacontrol() {
      var url = "lic/";
      var result = null;

      this.$http.get(url).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          if (result.size > 0) {
            this.size = result.size;
            this.desserts = result.objeto;
          } else {
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
          }
        }
      });
    },
    cargardataempresa() {
      var url = "ver/lista/";
      var result = null;
      this.$http.get(url).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          if (result.size > 0) {
            this.size = result.size;
            this.empresas = result.objeto;
          } else {
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
            console.log(this.mensaje);
          }
        }
      });
    },
    cargardataservicio() {
      var url = "ser/lista/";
      var result = null;
      this.$http.get(url).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          if (result.size > 0) {
            this.size = result.size;
            this.servicios = result.objeto;
          } else {
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
          }
        }
      });
    },
    cargarelemts(idservicio) {
      const elementservice = [];
      for (let i = 0; i < this.desserts.length; i++) {
        if (this.desserts[i].idservicio == idservicio) {
          elementservice.push(this.desserts[i]);
        }
      }
      this.apis = elementservice;
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "exitoso" : "fallido";
        console.log("tag", "Copiado " + msg);
      } catch (err) {
        console.log("tag", "no se pudo copiar");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    editItemsub(item) {
      this.dialogsub = !this.dialogsub;
      this.licencia = item;
      this.sublicencias = [];
      for (var emp in this.empresas) {
        if (this.empresas[emp].ruc == item.ruc) {
          this.empresa = this.empresas[emp];
        }
      }
      this.cargardatasublicencia(item);
      this.cargardatasubempresa(this.empresa);
    },
    cargardatasubempresa(item) {
      var url = "ver/sub/list/";
      var result = null;
      const postData = {
        ruc: item.ruc,
        idempresa: item.idempresa,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          this.subempresas = result.objeto;
        }
      });
    },
    addsublicencia(item) {
      var url = "lic/sub/";
      var result = null;
      const postData = {
        idsublicencia: this.selectsubempresa.idsubempresa,
        idlicencia: item.idlicencia,
        ruc: item.ruc,
        idservicio: this.servicio,
        api: this.apisub,
        api2: this.apisub2,
        estado: 1,
        servidor: this.servidorsub,
        contrasenia: this.contraseniasub,
        basedatos: this.basedatossub,
        usuario: this.usuariosub,
        nrolicencias: this.nrolicenciassub,
        tipo: 1,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.formatdate();
          this.cargardatasublicencia(item);
        }
      });
    },
    savesub(itemsub, licencia) {
      var url = "lic/sub/";
      var result = null;
      const postData = {
        idsublicencia: itemsub.idsublicencia,
        idlicencia: itemsub.idlicencia,
        ruc: itemsub.ruc,
        idservicio: itemsub.idservicio,
        api: itemsub.api,
        api2: itemsub.apiv,
        estado: itemsub.estado,
        servidor: itemsub.servidor,
        contrasenia: itemsub.contrasenia,
        basedatos: itemsub.basedatos,
        usuario: itemsub.usuario,
        nrolicencias: itemsub.nrolicencias,
        tipo: 1,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.cargardatasublicencia(licencia);
        }
      });
    },
    deleteItemsub(itemsub, licencia) {
      var url = "lic/sub/";
      var result = null;
      const postData = {
        idsublicencia: itemsub.idsublicencia,
        idlicencia: itemsub.idlicencia,
        ruc: itemsub.ruc,
        idservicio: itemsub.idservicio,
        api: itemsub.api,
        api2: itemsub.apiv,
        estado: itemsub.estado,
        servidor: itemsub.servidor,
        contrasenia: itemsub.contrasenia,
        basedatos: itemsub.basedatos,
        usuario: itemsub.usuario,
        nrolicencias: itemsub.nrolicencias,
        tipo: 0,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.cargardatasublicencia(licencia);
        }
      });
    },
    cargardatasublicencia(item) {
      console.log("licencia", item);
      var url = "lic/sub/list/";
      var result = null;
      const postData = {
        ruc: item.ruc,
        idlicencia: item.idlicencia,
        idservicio: item.idservicio,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          this.sublicencias = result.objeto;
          console.log("sublicencias", this.sublicencias);
        }
      });
    },
  },
};
</script>
